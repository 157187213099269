import React, { useState } from "react";
import Particle from "../Particle";
import { Container } from "react-bootstrap";
import { Row, Typography, Col, Layout } from "antd";
import { Form, Input, Button, Upload, message } from "antd";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { UploadOutlined } from "@ant-design/icons";
import emailjs from "emailjs-com";
import '../../index.css';

function JoinUs() {
  const [loading, setLoading] = useState(false);

  const handleFinish = (values) => {
    setLoading(true);

    // Prepare the email data
    const templateParams = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
      link: values.link,
    };

    // Attach the resume file if present
    if (values.resume && values.resume.fileList[0]) {
      const resumeFile = values.resume.fileList[0].originFileObj;
      templateParams.resume = resumeFile;
    }

    // Send email using EmailJS
    emailjs
      .send("your_service_id", "your_template_id", templateParams, "your_user_id")
      .then(
        () => {
          message.success("Your application has been sent successfully!");
          setLoading(false);
        },
        (error) => {
          message.error("Failed to send application. Please try again later.");
          console.error("EmailJS error:", error);
          setLoading(false);
        }
      );
  };

  const inputStyle = {
    backgroundColor: 'rgb(20, 15, 35)',
    borderColor: 'rgba(108, 173, 220, 0.959)',
    color: 'white',
  };

  const buttonStyle = {
    backgroundColor: 'rgba(108, 173, 220, 0.959)',
    borderColor: 'rgba(108, 173, 220, 0.959)',
    color: 'white',
    borderRadius: '25px',
  };

  const typo = {
    color: 'white',
    textAlign: 'left',
    paddingBottom: '10px'
  };

  return (
    <>
      <Container fluid className="about-section">
        <Particle />
        <Container style={{ paddingTop: '50px', paddingBottom: '20px' }}>
          <Row style={{ marginTop: '50px' }}>
            <Col md={10} lg={10}>
              <Slide left>
                <p
                  className="heading-name fade-in fade-in-2"
                  style={{
                    color: "white",
                    textAlign: "left",
                    fontSize: "45px",
                    marginBottom: "20px",
                  }}
                >
                  Join <span style={{ color: 'rgba(108, 173, 220, 0.959)' }}> Us </span>
                </p>
                <Typography style={{ color: 'white', textAlign: 'left' }}>
                  NeuroDepth, a forward-thinking technology startup headquartered in Bangalore. We stand at the forefront of technological innovation, dedicated to enhancing the quality of human existence.
                </Typography>
                <Layout style={{ textAlign: "left", marginTop: '20px', paddingBottom: '10px', backgroundColor: 'rgb(20 15 35)' }}>
                  <Typography style={{ color: 'white', paddingBottom: '5px' }}> Email: contact@neurodepth.in</Typography>
                  <Typography style={{ color: 'white', paddingBottom: '5px' }}> Address:  Bangalore, India</Typography>
                </Layout>
              </Slide>
            </Col>
            <Col md={12} lg={12} offset={1}>
              <Fade right>
                <Form
                  name="contact-form"
                  layout="vertical"
                  onFinish={handleFinish}
                  className="contact-form"
                >
                  <Typography style={typo}>Name</Typography>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please enter your name", }]}
                  >
                    <Input placeholder="Enter your name" style={inputStyle} />
                  </Form.Item>
                  <Typography style={typo}>Email</Typography>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" style={inputStyle} />
                  </Form.Item>
                  <Typography style={typo}>Phone</Typography>
                  <Form.Item
                    name="phone"
                    rules={[{ required: true, message: "Please enter your Phone number" }]}>
                    <Input placeholder="Enter your Phone" style={inputStyle} />
                  </Form.Item>
                  <Typography style={typo}>Message</Typography>
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "Please enter your message" }]}>
                    <Input.TextArea style={inputStyle} />
                  </Form.Item>
                  <Typography style={typo}>Link to external profile eg GitHub, google scholars</Typography>
                  <Form.Item
                    name="link"
                    rules={[{ required: false, message: "Link to external profile eg GitHub, google scholars" }]}>
                    <Input placeholder="Enter external profile link" style={inputStyle} />
                  </Form.Item>
                  <Typography style={typo}>Upload Resume</Typography>
                  <Form.Item
                    name="resume"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                    rules={[{ required: true, message: "Please upload your resume" }]}
                  >
                    <Upload
                      name="resume"
                      beforeUpload={() => false}
                      maxCount={1}
                      className="custom-upload"
                    >
                      <Button icon={<UploadOutlined />} style={buttonStyle}>
                        Click to Upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={buttonStyle} block loading={loading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default JoinUs;
