import React from 'react';
import './MissionVision.css';
import { Container } from "react-bootstrap";
import Particle from "../Particle";

const MissionVision = () => {
  return (
    <Container fluid className="about-section">
        <Particle />
    <div className="mission-vision-container" style={{marginBottom:'0px'}}>
      <div className="column">
        <h2>Mission</h2>
        <p>Fostering an ecosystem conducive to the innovation, development, and rigorous evaluation of intelligent and adaptable devices tailored to address tangible real-world challenges</p>
      </div>
      <div className="column">
        <h2>Vision</h2>
        <p>Fueled by Superintelligence, we endeavor to guide humanity beyond mortality’s grasp, envisioning a future where life’s boundaries are redefined through advanced technology.</p>
      </div>
    </div>
    </Container>
  );
}

export default MissionVision;
