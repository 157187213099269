import React from "react";
import { Card, Row, Col, Flex } from "antd";
import { Container } from "react-bootstrap";
import { Image } from "react-bootstrap";
import abin from "../../Assets/Projects/abin.jpg";
import arun from "../../Assets/Projects/arun.jpg";
//import agnesh from "../../Assets/Projects/user.jpeg";
import manas from "../../Assets/Projects/Manas.jpg";
import vishnu from "../../Assets/Projects/vishnu.jpg";
import Agnesh from "../../Assets/Projects/user.png";

import "./project.css";
import Particle from "../Particle";

const teamMembers = [
  { name: "Abin P.", designation: "Research Scientist", imgPath: abin },
  { name: "Arun KV", designation: "Research Scientist", imgPath: arun },
  //  { name: "Agnesh L", designation: "Research Scientist", imgPath: agnesh },
  { name: "SR Manas", designation: "Research Scientist", imgPath: manas },
  { name: "Vishnu", designation: "Research Scientist", imgPath: vishnu },
  { name: "Agnesh", designation: "Research Scientist", imgPath: Agnesh },

];

const TeamCard = () => {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Flex gap="middle" vertical style={{ padding: '50px', marginTop: '30px' }}>
        <p
          className="heading-name fade-in fade-in-2"
          style={{
            color: "white",
            textAlign: "Center",
            fontSize: "45px",
            marginBottom: "20px",
          }}
        >
          Our <span style={{ color: 'rgba(108, 173, 220, 0.959)' }}>Leadership Team</span>
        </p>
        <Row gutter={[16, 16]} >
          {teamMembers.map((member, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Card className="team-card" bordered={false} style={{ textAlign: "center" }}>
                <div className="image-container">
                  <Image
                    src={member.imgPath}
                    roundedCircle
                    className="team-image"
                  />
                </div>
                <h4 style={{ color: 'white' }}>{member.name}</h4>
                <p>{member.designation}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </Flex>
    </Container>
  );
};

export default TeamCard;
