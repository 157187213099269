import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/Home.jpg";
import '../../index.css';
import MissionVision from "../vission/vission";
import Particle from "../Particle";
import About from "../About/About";
import Projects from "../Projects/Projects";
import JoinUs from "../JoinUs/JoinUs";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading fade-in fade-in-1">
                Ensuring a Future{" "}
              </h1>
              <h1 className="heading-name fade-in fade-in-2">
                Beyond Boundaries
                <strong className="main-name"> with Safe Superintelligence</strong>
              </h1>
            </Col>
            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <MissionVision />
      <About />
      <Projects />
      <JoinUs />
    </section>
  );
}

export default Home;
