import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/Projects/about.jpg";
import { Typography } from "antd";
import Fade from "react-reveal/Fade";
import { useSpring, animated } from "react-spring";
import "./AboutCard.css";

function About() {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1},
  });

  return (
    <animated.div style={fadeIn}>
      <Container fluid className="about-section">
        <Particle />
        <Container style={{paddingTop:'20px',paddingBottom:'50px'}}>
          <Row style={{ justifyContent: "center", padding: "10px",marginTop:'50px'}}>
            <p
             className="heading-name fade-in fade-in-2"
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "45px",
                marginBottom: "20px",
              }}
            >
              About <span style={{color:'rgba(108, 173, 220, 0.959)'}}> Us </span>
            </p>
            <Col md={20} lg={20} style={{ justifyContent: "center" }}>
              <Fade bottom>
                <Typography
                  className="about-text"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  NeuroDepth, a forward-thinking technology startup headquartered in Bangalore. We
                  stand at the forefront of technological innovation, dedicated to enhancing the quality of
                  human existence. Our mission is to foster an interconnected ecosystem of advanced
                  devices designed to uplift and improve our well-being.
                </Typography>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="about-img" style={{ paddingLeft: "14px" }}>
              <Fade left>
                <Typography
                  className="about-text"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginTop: "20px",
                    textAlign: "justify",
                  }}
                >
                  At NeuroDepth, we believe in pushing the boundaries of what is possible. Our team of
                  visionary engineers, researchers, and thought leaders are committed to developing
                  cutting-edge technologies that address current challenges and anticipate future needs.
                  By integrating artificial intelligence, Machine learning, and human-centric design, we
                  create intelligent and adaptive solutions that remain relevant in a rapidly evolving world.
                </Typography>
                <Typography
                  className="about-text"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    textAlign: "justify",
                    marginTop: "20px",
                  }}
                >
                  Central to our vision is the development of safe intelligent systems. We prioritize the
                  ethical implications and safety of our AI developments, aligning with our goal to guide
                  humanity beyond mortality’s grasp. By ensuring our technologies are designed and
                  implemented responsibly, we contribute to a future where advanced technology
                  enhances human life without compromising safety or ethical standards.
                </Typography>
              </Fade>
            </Col>
            <Col md={6} className="about-img">
              <Fade right>
                <img src={laptopImg} alt="about" className="img-fluid" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </Container>
    </animated.div>
  );
}

export default About;
